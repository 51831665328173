import { Button, Col, Row, Typography, Form, Input, Select, InputNumber, Collapse, Descriptions, Tooltip } from "antd";
import { useState, useEffect } from "react";
import cookie from "react-cookies";
import {
  toGetArticlesList,
  toDeleteArticle,
  toAddOrUpdateArticle,
  toGetSearchArticlesList,
  toGetArticlesName,
  toGetMarqueList,
  toGetCategoryList,
  toGetEmplacementList,
  toFiltersArticlesList,
  toChangeStatusArticle,
  toGetStockValue,
} from "./Api";
import { BiPencil, BiPrinter } from "react-icons/bi";
import printJS from "print-js";

import { PlusOutlined, FileTextOutlined, RiseOutlined } from "@ant-design/icons";
import DataTable from "react-data-table-component";
import { PrinterOutlined } from "@ant-design/icons";

import moment from "moment";
import ArticleModal from "./ArticleModal";
import ArticleDetail from "./ArticleDetail";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";
import { successMsg, errorMsg, toFormatArticleClassifications, numberFormatCurrency } from "../../Utils/Utils";
import Modal from "antd/lib/modal/Modal";
import Tecdoc from "../../Utils/Tecdoc";
import Article from "../../Models/Article";
import TecdocLiaison from "./TecdocLiaison";
import { DataExistsException } from "../../DataExitsException";
import ArticleHistory from "./ArticleHistory";

import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import { SearchOutlined } from "@ant-design/icons";
var Barcode = require("react-barcode");

const ls = require("localstorage-ttl");
const { Option } = Select;
const { Panel } = Collapse;
moment.updateLocale("fr", localization);

const Articles = () => {
  const [form] = Form.useForm();
  const [formFilter] = Form.useForm();
  const [marques, setMarques] = useState([]);
  const [articleHistory, setArticleHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [tecdocModalVisible, setTecdocModalVisible] = useState(false);
  const [detailVisble, setDetailVisible] = useState(false);
  const [tecdocLiaison, setTecdocLiaison] = useState(false);
  const [page, setPage] = useState(0);
  const [article, setArticle] = useState("");
  const [firstSearch, setFirstSearch] = useState(false);
  const [categories, setCategories] = useState([]);
  const [emplacements, setEmplacements] = useState([]);
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });
  const [articleDisplay, setArticleDisplay] = useState("");
  const [articles, setArticles] = useState([]);
  const [articlesAll, setArticlesAll] = useState([]);
  const [articlesNames, setArticleNames] = useState([]);
  const [show, setShow] = useState(true);
  const [activeKey, setActiveKey] = useState(-1);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [articleBarCode, setArticleBarCode] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    toGetDatas(0);
    form.setFieldsValue({
      categorie: "0",
      emplacement: "0",
      marque: "0",
      searchAvancée: "",
      searchWord: "Tout",
      tecdoc: "0",
      type: "0",
      stock: "Tout",
      stockValue: "0",
    });
    toGetStockValue(form.getFieldsValue(), page).then((res) => {
      setData(res);
    });
  }, []);

  const callback = (key) => {
    setActiveKey(key);
  };
  const toGetDatas = () => {
    toGetMarqueList().then((res) => {
      setMarques(res);
    });
    toGetCategoryList().then((res) => {
      setCategories(res);
    });
    toGetEmplacementList().then((res) => {
      setEmplacements(res);
    });
    setLoading(true);
    if (ls.get(`articles`) != null) {
      setArticles(ls.get(`articles`));
      setArticlesAll(ls.get(`articles`));
      setLoading(false);
    }
    toGetArticlesList(0).then((res) => {
      setArticles(res);
      setArticlesAll(res);
      setLoading(false);
    });

    if (null != ls.get("articles_name")) {
      setArticleNames(ls.get("articles_name"));
    }
    toGetArticlesName().then((res) => {
      setArticleNames(res);
    });
    setShow(false);
  };

  const afterBind = () => {
    if (searchValue != "") {
      setTecdocLiaison(false);

      onSearch(searchValue);
      setLoading(false);
    } else {
      setTecdocLiaison(false);

      toSearch();
    }
  };

  const onSearch = (value, ref) => {
    if (ref) {
      form.setFieldsValue({ searchWord: "" });
    } else {
      form.setFieldsValue({ searchAvancée: "" });
    }
    setSearchValue(value);
    if (value.length == 1) {
      errorMsg("Veuillez entrer 2 caractères merci!");
    } else {
      setLoading(true);
      toGetArticles(value, ref);
    }
  };

  const toGetArticles = async (search, ref) => {
    if (search == "") {
      //todo change this in react
      toGetArticlesList(0).then((data) => {
        setPage(0);
        setArticles(data);
        setArticlesAll(data);

        setFirstSearch(false);
        setLoading(false);
      });
    } else {
      toGetSearchArticlesList(search).then((res) => {
        setArticles(res);
        setArticlesAll(res);
        setFirstSearch(true);
        setLoading(false);
      });
    }
  };

  const saveArticle = async (id, article, automaticCode) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article, automaticCode);

      if (id == -1) {
        setArticles([...articles, newArticle]);
        successMsg("Article créé avec succés");
      } else {
        const articleIndex = articles.findIndex((c) => c.id == id);
        const newArticles = [...articles];
        newArticles[articleIndex] = {
          ...articles[articleIndex],
          ...newArticle,
        };
        setArticles(newArticles);
        successMsg("Article modifié avec succés");
      }
      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }
  };

  const toEdit = (article) => {
    setArticleDetails({
      id: article.id,
      titre: article.s__firstTitle,
      titre2: article.s__secondTitle,
      reference: article.s__reference,
      code: article.s__barCode,
      type: article.s__type,
      unite: article.unite,
      marque: article.marqueTitle,
      categorie: article.categoryTitle,
      emplacement: article.emplacement,
      seuil: article.l__seuil,
      tva: article.tva,
      prixAchatHT: article.l__prixAchatHT,
      prixAchatTTC: article.l__prixAchatTTC,
      data: article.data,
      b__tecdoc: article.b__tecdoc,
      marge: article.l__marge,
      margeValue: article.l__margeValue,
      prixVenteHT: article.l__prixVenteHT,
      prixVenteTTC: article.l__prixVenteTTC,

      coefficient: article.coefficient,
      prixAchatDevise: article.prixAchatDevise,

      margeGros: article.l__margeGros,
      margeGrosValue: article.l__margeGrosValue,
      prixVenteGrosHT: article.l__prixVenteGrosHT,
      prixVenteGrosTTC: article.l__prixVenteGrosTTC,
    });
    setCreateModalVisible(true);
  };

  const toDisplayArticle = (article) => {
    setArticleDisplay({
      id: article.id,
      titre: article.s__firstTitle,
      titre2: article.s__secondTitle,
      reference: article.s__reference,
      code: article.s__barCode,
      type: article.s__type,
      unite: article.unite,
      marque: article.marqueTitle,
      categorie: article.categoryTitle,
      emplacement: article.emplacement,
      seuil: article.l__seuil,
      tva: article.tva,
      prixAchatHT: article.l__prixAchatHT,
      prixAchatTTC: article.l__prixAchatTTC,
      data: article.data,
      b__tecdoc: article.b__tecdoc,
      marge: article.l__marge,
      margeValue: article.l__margeValue,
      prixVenteHT: article.l__prixVenteHT,
      prixVenteTTC: article.l__prixVenteTTC,

      coefficient: article.coefficient,
      prixAchatDevise: article.prixAchatDevise,

      margeGros: article.l__margeGros,
      margeGrosValue: article.l__margeGrosValue,
      prixVenteGrosHT: article.l__prixVenteGrosHT,
      prixVenteGrosTTC: article.l__prixVenteGrosTTC,
    });
    setDetailVisible(true);
  };
  const toAdd = () => {
    setArticleDetails(new Article());
    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const addFromTecdoc = async (article) => {
    var a = new Article();
    a.titre = article.fullName;
    a.reference = article.ref;
    a.marque = article.brand.name;
    a.type = "Adaptable";
    a.categorie = article.fullName;
    a.data = toFormatArticleClassifications(article);
    a.referenceTecdoc = article.ref;
    a.fromTecdoc = true;

    setArticleDetails(a);

    setCreateModalVisible(true);
  };

  const exitModalTecdoc = () => {
    setTecdocModalVisible(false);
  };

  const toDeleteArticleAction = (id) => {
    toDeleteArticle(id).then((res) => {
      successMsg("Article supprimé avec succée");
      toSearch();
    });
  };

  const toSearch = () => {
    setSelectedArticles([]);
    setActiveKey(-1);

    setShow(true);
    toGetStockValue(form.getFieldsValue(), page).then((res) => {
      setData(res);
    });
    if (
      form.getFieldValue("categorie") == "0" &&
      form.getFieldValue("emplacement") == "0" &&
      form.getFieldValue("marque") == "0" &&
      form.getFieldValue("tecdoc") == "0" &&
      form.getFieldValue("type") == "0" &&
      form.getFieldValue("stock") == "Tout" &&
      form.getFieldValue("searchWord") == "Tout" &&
      form.getFieldValue("searchAvancée") == ""
    ) {
      toGetArticlesList(0).then((res) => {
        setPage(page + 1);
        setArticles(res);
        setArticlesAll(res);
        setLoading(false);
        setShow(false);
      });
      return;
    }

    toFiltersArticlesList(form.getFieldsValue(), page).then((res) => {
      setArticles(res);
      setArticlesAll(res);
      setLoading(false);
      setShow(false);
    });
  };
  const toChangeStatus = (id, actif) => {
    toChangeStatusArticle(id, !actif).then((res) => {
      const all = [...articles];
      all.filter((el) => el.id == id)[0].b__active = !actif;
      setArticles(all);
    });
  };

  const conditionalRowStyles = [
    {
      when: (row) => moment(new Date()).diff(moment(row.dtVente != "-" ? row.dtVente : "2023-01-01"), "days") > 30,
      style: {
        backgroundColor: "#fff2cc",
      },
    },
    {
      when: (row) => moment(new Date()).diff(moment(row.dtVente != "-" ? row.dtVente : "2023-01-01"), "days") > 90,
      style: {
        backgroundColor: "#f9cb9c",
        color: "white",
      },
    },
    {
      when: (row) => moment(new Date()).diff(moment(row.dtVente != "-" ? row.dtVente : "2023-01-01"), "days") > 180,
      style: {
        backgroundColor: "#ff7b7b",
        color: "white",
      },
    },
  ];

  const columns = [
    {
      name: "TD",
      selector: "b__tecdoc",
      format: (article) => (
        <img
          onClick={() => {
            setTecdocLiaison(true);
            setArticle(article);
          }}
          src="/images/tecdoc.png"
          className={article.b__tecdoc ? "auto-tecdoc-img" : "auto-tecdoc-img-gray"}
          alt="smtv-logo"
        />
      ),
      grow: 0.25,
    },
    {
      name: "Titre",
      selector: "s__secondTitle",
      grow: 2.5,
    },
    {
      name: "Référence",
      selector: "s__reference",
    },
    {
      name: "Marque",
      selector: "marqueTitle",
    },
    {
      name: "Date dernière vente",
      selector: "dtVente",
    },
    {
      name: "Date dernier achat",
      selector: "dtAchat",
    },

    {
      name: "Prix de vente HT",
      selector: "displayableName",
      sortable: true,
      format: (article) => `${numberFormatCurrency(article.l__prixVenteHT)} `,
    },
    {
      name: "Prix de vente TTC",
      selector: "displayableName",
      sortable: true,
      format: (article) => `${numberFormatCurrency(article.l__prixVenteTTC)} `,
    },

    {
      name: "Stock",
      selector: "stock",
      format: (article) => (
        <span
          className={article.stock <= 0 ? "auto--color-danger" : article.stock < article.l__seuil ? "auto--color-warning" : "auto--color-success"}
        >
          {article.stock}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: "stock",
      grow: 1.5,
      format: (article) => (
        <>
          <Button type="link" size="large" icon={<FileTextOutlined />} onClick={(e) => toDisplayArticle(article)}></Button>
          <Button
            type="link"
            size="large"
            icon={<RiseOutlined />}
            onClick={(e) =>
              setArticleHistory({
                id: article.id,
                titre: article.s__firstTitle,
                titre2: article.s__secondTitle,
                reference: article.s__reference,
                code: article.s__barCode,
                type: article.s__type,
                unite: article.unite,
                marque: article.marqueTitle,
                categorie: article.categoryTitle,
                emplacement: null != article.emplacement ? article.emplacement.title : "",
                seuil: article.l__seuil,
                tva: article.tva,
                prixAchatHT: article.l__prixAchatHT,
                prixAchatTTC: article.l__prixAchatTTC,
                data: article.data,
                b__tecdoc: article.b__tecdoc,
                marge: article.l__marge,
                margeValue: article.l__margeValue,
                prixVenteHT: article.l__prixVenteHT,
                prixVenteTTC: article.l__prixVenteTTC,

                margeGros: article.l__margeGros,
                margeGrosValue: article.l__margeGrosValue,
                prixVenteGrosHT: article.l__prixVenteGrosHT,
                prixVenteGrosTTC: article.l__prixVenteGrosTTC,
              })
            }
          ></Button>
          <Button type="link" icon={<BiPencil />} onClick={(e) => toEdit(article)} />

          <Tooltip title="Pour imprimer le code à barre">
            <Button type="link" icon={<BiPrinter />} onClick={(e) => toOpenModal(article)} />
          </Tooltip>
          {/* {!article.b__locked && (
            <Tooltip title="Pour supprimer l'article">
              <Button
                type="link"
                icon={<BiTrash />}
                onClick={(e) => toDeleteArticleAction(article.id)}
              />
            </Tooltip>
          )} */}
          {/* <Switch
            checked={article.b__active}
            onChange={() => toChangeStatus(article.id, article.b__active)}
          ></Switch> */}
        </>
      ),
    },
  ];
  const toOpenModal = (article) => {
    setArticleBarCode(article);
    setOpen(true);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const onSelectRow = (e) => {
    setSelectedArticles(e.selectedRows);
  };

  const getJsonToPrint = () => {
    var res = [];
    selectedArticles.forEach((element) => {
      res.push({
        titre: element.s__secondTitle,
        référence: element.s__reference,
        marque: element.marqueTitle,
        qte: element.stock,
        prix: element.l__prixVenteTTC.toFixed(3),
        prixHT: element.l__prixVenteHT.toFixed(3),
      });
    });
    return res;
  };

  const getHeader = () => {
    var result = "";
    if (form.getFieldValue("searchWord") != "Tout") {
      result += "-Désignation: " + form.getFieldValue("searchWord");
    }
    if (form.getFieldValue("searchAvancée") != "") {
      result += " -Référence: " + form.getFieldValue("searchAvancée");
    }
    if (form.getFieldValue("emplacement") != "0") {
      result += " -Emplacement: " + form.getFieldValue("emplacement");
    }
    if (form.getFieldValue("categorie") != "0") {
      result += " -Catégorie: " + form.getFieldValue("categorie");
    }
    if (form.getFieldValue("marque") != "0") {
      result += " -Marque: " + form.getFieldValue("marque");
    }
    if (form.getFieldValue("tecdoc") != "0") {
      result += " -Tecdoc: " + form.getFieldValue("tecdoc");
    }
    if (form.getFieldValue("type") != "0") {
      result += " -Type: " + form.getFieldValue("type");
    }
    if (form.getFieldValue("stock") != "Tout") {
      result += " -Stock: " + form.getFieldValue("stock") + " " + form.getFieldValue("stockValue");
    }
    return result;
  };

  const toPrint = () => {
    // var container = document.getElementById("to-print");

    // var width = "100%";
    // var height = "100%";
    // var printWindow = window.open("", "PrintMap");
    // printWindow.document.writeln(container.innerHTML);
    // printWindow.document.close();
    // printWindow.print();
    // printWindow.close();

    var container = document.getElementById("to-print");
    var mySVG = document.getElementById("barcode-canvas");
    var width = "100%";
    var height = "100%";
    var printWindow = window.open("", "PrintMap", "width=" + width + ",height=" + height);
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
    setOpen(false);
    setArticleBarCode(null);
  };

  return (
    <div id="achat">
      {null != articleBarCode && (
        <Modal
          title={""}
          visible={open}
          onCancel={() => {
            setOpen(false);
            setArticleBarCode(null);
          }}
          footer={[<Button onClick={() => toPrint()}>Imprimer</Button>]}
        >
          <div id="to-print" style={{ textAlign: "center", border: "1px solid red" }}>
            <p>
              <b>{articleBarCode.s__secondTitle + " / " + articleBarCode.s__reference}</b>
            </p>
            <Barcode height={"30"} value={articleBarCode.s__barCode} />,
          </div>
        </Modal>
      )}
      <Loading show={show} color="red" showSpinner={true} />

      <Form>
        <Row gutter="12" className="auto--custom-header">
          <Col span="12">
            <Typography.Title level={3}>Articles</Typography.Title>
          </Col>

          <Col span="12" style={{ textAlign: "right" }}>
            <Button icon={<PlusOutlined />} onClick={(e) => toAdd()} type="primary">
              Ajouter un article
            </Button>

            <Button icon={<PlusOutlined />} style={{ marginLeft: "1vh" }} onClick={(e) => setTecdocModalVisible(true)} type="primary">
              Ajouter un article via Tecdoc
            </Button>
          </Col>
        </Row>
      </Form>

      <Form
        form={form}
        layout="vertical"
        //onValuesChange={(e) => onValuesChange(e)}
      >
        <Row gutter="6" className="auto--custom-header">
          <Col md={4}>
            <Form.Item label="Désignation" style={{ marginBottom: 0 }} name="searchWord">
              <Select
                showSearch
                placeholder="Recherche par désignation..."
                optionFilterProp="children"
                defaultValue="Tout"
                onChange={() => toSearch()}
              >
                <Option value="Tout">Tout</Option>

                {articlesNames.map((name) => (
                  <Option value={name}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Référence, titre, code à barre" name="searchAvancée">
              <Input.Search
                //value={searchValue}
                onSearch={() => toSearch()}
                placeholder="Recherche"
                enterButton
              />
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item name="tecdoc" label="Tecdoc">
              <Select defaultValue="0" onChange={() => toSearch()} optionFilterProp="children">
                <Option value="0">Tout</Option>
                <Option value="1">Liée avec tecdoc</Option>
                <Option value="2">Non liée avec tecdoc</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label="Type" name="type">
              <Select showSearch defaultValue="0" onChange={() => toSearch()}>
                <Select.Option value="0">Tout</Select.Option>
                <Select.Option value="Origine">Origine</Select.Option>
                <Select.Option value="Adaptable">Adaptable</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Marque" name="marque">
              <Select showSearch style={{ width: "100%" }} defaultValue="0" onChange={() => toSearch()}>
                <Option value="0">Tout</Option>
                {marques.map((marque, i) => (
                  <Option value={marque.title} key={i}>
                    {marque.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Catégorie" name="categorie">
              <Select showSearch style={{ width: "100%" }} defaultValue="0" onChange={() => toSearch()}>
                <Option value="0">Tout</Option>
                {categories.map((c, i) => (
                  <Option value={c.title} key={i}>
                    {c.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Emplacement" name="emplacement">
              <Select showSearch style={{ width: "100%" }} defaultValue="0" onChange={() => toSearch()}>
                <Option value="0">Tout</Option>
                <Option value="-1">Pas d'emplacement</Option>
                {emplacements.map((e, i) => (
                  <Option value={e.title} key={i}>
                    {e.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter="6" className="auto--custom-header">
          <Col span="2">
            <Form.Item label="Stock" name="stock">
              <Select defaultValue="Tout" onChange={() => toSearch()}>
                <Option value="Tout">Tout</Option>

                <Option value="=">Egal</Option>

                <Option value=">">Supérieur</Option>
                <Option value="<">Inférieur</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label name="stockValue">
              <InputNumber onChange={() => toSearch()} />
            </Form.Item>
          </Col>
          {/* <Col span="3">
                <Form.Item label="Date de création" name="date">
                  <Select>
                    <Option value="aujourdhui">Aujourd'hui</Option>
                    <Option value="hier">Hier</Option>
                    <Option value="cemois">Ce mois</Option>
                  </Select>
                </Form.Item>
              </Col> */}

          <Col span="3">
            <Form.Item label name="stockValue">
              <Button type="primary" icon={<SearchOutlined />} onClick={() => toSearch()}>
                Chercher
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row
        style={{
          overflowY: "hidden",
          marginTop: "2vh",
        }}
      >
        <Col span="23">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={articles}
            pagination
            selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas d'articles trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            conditionalRowStyles={conditionalRowStyles}
          />
          {cookie.load("role") == "Super Admin" && null != data && (
            <Descriptions bordered style={{ backgroundColor: "white", marginTop: "3px" }}>
              <Descriptions.Item label="Quantité" className="item-label">
                {data.qte}
              </Descriptions.Item>
              <Descriptions.Item label="Valeur Stock" className="item-label">
                {numberFormatCurrency(data.value)}
              </Descriptions.Item>
            </Descriptions>
          )}
          {selectedArticles.length > 0 && (
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              onClick={() =>
                printJS({
                  printable: getJsonToPrint(),
                  style: "body { margin: 0; font-size: 12px; }",
                  properties: ["titre", "référence", "marque", "qte", "prixHT", "prix"],
                  type: "json",
                  header: "<h4>" + getHeader() + "</h4>",
                })
              }
            >
              Imprimer
            </Button>
          )}
        </Col>
      </Row>
      <ArticleModal saveArticle={saveArticle} details={articleDetails} createModalVisible={createModalVisible} closeCreateModal={exitCreateModal} />

      <Modal
        className="smtv-modal modal-large"
        visible={tecdocModalVisible}
        maskClosable={false}
        onCancel={exitModalTecdoc}
        footer={[
          <Button key="back" onClick={exitModalTecdoc}>
            Cancel
          </Button>,
        ]}
      >
        <Tecdoc reference="" displayTitle={false} addFromTecdoc={addFromTecdoc} />
      </Modal>

      <ArticleDetail setDetailVisible={setDetailVisible} article={articleDisplay} visible={detailVisble} />
      {null != articleHistory && (
        <ArticleHistory setArticleHistory={() => setArticleHistory(null)} article={articleHistory} visible={articleHistory != null} />
      )}

      <Modal
        className="smtv-modal modal-large"
        visible={tecdocLiaison}
        maskClosable={false}
        title={article.s__secondTitle + " " + article.s__reference}
        onCancel={(e) => setTecdocLiaison(false)}
        footer={[
          <Button key="back" onClick={(e) => setTecdocLiaison(false)}>
            Fermer
          </Button>,
        ]}
      >
        <TecdocLiaison selectedArticle={article} setTecdocLiaison={() => afterBind(false)} />
      </Modal>
    </div>
  );
};

export default Articles;
