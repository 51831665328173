import { Col, Descriptions, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";

import "../../../Styles/TwoTables.scss";
import { toGetPerYear } from "../APIs";

const TotalBl = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    toGetPerYear().then((res) => {
      setData(res);
    });
  };
  return (
    <>
            <Descriptions column={5} bordered size='small' style={{ backgroundColor: "white", marginTop: "3px" }}>

      {data.map((el) => (
        <>
          <Descriptions.Item label='Année' className='item-label'>
            {el.year}
          </Descriptions.Item>

          <Descriptions.Item label='Total TTC' className='item-label'>
            {el.total + " DT"}
          </Descriptions.Item>
          <Descriptions.Item label='Total payé' className='item-label'>
            {el.totalPaied + " DT"}
          </Descriptions.Item>
          <Descriptions.Item label='Total Reste' className='item-label'>
            {el.totalUnpaied + " DT"}
          </Descriptions.Item>
          <Descriptions.Item label='Nombre BL' className='item-label'>
            {el.nb}
          </Descriptions.Item>
          </>
      ))}
              </Descriptions>

    </>
  );
};

export default TotalBl;
