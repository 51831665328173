import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col, Tabs, Descriptions, Empty } from "antd";
import { toGetBL, toPrintBL } from "../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import GaugeChart from "react-gauge-chart";
import cookie from "react-cookies";

const { TabPane } = Tabs;

const BonLivraisonModal = ({ id, onClose, entete }) => {
  const [blItems, setBlItems] = useState(null);
  const [bl, setBl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reglements, setReglements] = useState([]);
  const [marge, setMarge] = useState(-1);
  const [margeAmount, setMargeAmount] = useState(-1);

  useEffect(() => {
    if (null != id) {
      setLoading(true);

      toGetBL(id).then((res) => {
        setBl(JSON.parse(res.bonlivraison));
        var bl = JSON.parse(res.bonlivraison);
        var items = bl.items;
                console.log(items);

        var regl = [];
        res.reglements.forEach((element) => {
          regl.push(element);
        });
        var prix_achat = items.reduce(
          (a, b) => a + b.d__achatPriceHT * b.d__quantity,
          0
        );
        var margeTotal = 0;
        if (items.length > 0) {
          margeTotal = Math.round(
            ((bl.d__totalHT - prix_achat) / prix_achat) * 100
          );
          setMargeAmount(bl.d__totalHT - prix_achat)
          if (margeTotal > 0) {
            setMarge(parseInt(margeTotal));
          } else {
            setMarge(0);
          }
        } else {
          setMarge(-1);
        }
        setReglements(regl);
        setLoading(false);
      });
    }
  }, [id]);

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 5:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const printBl = (e) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    toPrintBL(bl.id, titre1, entete);
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={id != null}
      onCancel={onClose}
      title="Details du bon de livraison"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={(e) => printBl(e)}
          onContextMenu={(e) => printBl(e)}
        >
          Imprimer
        </Button>
      ]}
    >
      <LoadingScreen
        loading={loading}
        bgColor="#f5f8fa"
        spinnerColor="#B9D9EB"
        textColor="#2D2926"
      >
        {bl != null && (
          <>
            <h1 style={{ textAlign: "center" }}>Bon Livraison</h1>
            <Row>
              <Col className="info-box" md={11}>
                <p>
                  <b>Ref</b> : {bl.s__ref}
                </p>
                <p>
                  <b>Date</b> : {toConvertDate(bl.dt__createdDate)}
                </p>
                <p>
                  <b>Chargé client</b> :
                  {bl.l__createdBy && bl.l__createdBy.username}
                </p>
              </Col>
              <Col className="info-box" md={11}>
                <h1>Client</h1>
                <p>
                  <b>Nom</b> :
                  {bl.displayableName
                    ? bl.displayableName
                    : bl.client && bl.client.s__name}
                </p>
                <p>
                  <b>Note</b> :{bl.s__note}
                </p>
                <p>
                  <b>Tel</b> : {bl.client && bl.client.s__phoneNumber}
                </p>
              </Col>
            </Row>
            <Row
              justify="center"
              //style={{ width: "200px", textAlign: "center" }}
            >
              <Col span="8">
                {marge != -1 && (
                  <>
                    <span style={{ marginLeft: "10%" }}>{marge + " %"}</span>
                    <br />
                    <GaugeChart
                      id="gauge-chart5"
                      nrOfLevels={100}
                      arcsLength={[0.1, 0.25, 0.65]}
                      colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                      percent={marge / 100}
                      textColor={"transparent"}
                      arcPadding={0.01}
                      style={{ height: "50%", width: "50%" }}
                    ></GaugeChart>
                    <br />
                  {cookie.load("role") == "Super Admin" && (
                    <span style={{ marginLeft: "10%" }}>{margeAmount.toFixed(3) + " DT"}</span>)}
                  </>
                )}
              </Col>
            </Row>
            <Tabs defaultActiveKey="1" centered>
              <TabPane tab="Articles" key="1">
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Ref
                      </th>
                      <th className="ant-table-cell">Désignation</th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Prix Unitaire HT
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Prix Unitaire TTC
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Quantité
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Remise
                      </th>
                      <th className="ant-table-cell" style={{ width: "8%" }}>
                        Prix Total
                      </th>
                      <th className="ant-table-cell" style={{ width: "5%" }}>
                        TVA
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {null != bl &&
                      bl.items.map((item) => (
                        <tr>
                          <td
                            className="ant-table-cell"
                            style={{ width: "8%" }}
                          >
                            {item.article.s__reference}
                          </td>
                          <td className="ant-table-cell">
                            {item.article.s__firstTitle}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__unitPriceHT.toFixed(3)}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__unitPriceTTC.toFixed(3)}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "10%" }}
                          >
                            {item.d__quantity}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "10%" }}
                          >
                            {parseFloat(item.d__discount).toFixed(2)} %
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__total.toFixed(3)} Dt
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "6%" }}
                          >
                            {item.d__tva} %
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </TabPane>
              <TabPane tab="Réglements" key="2">
                <Row>
                  <Col span="24">
                    <div
                      className="table-wrapper"
                      style={{
                        maxHeight: "40vh",
                        overflowY: "auto",
                        scrollbarWidth: "3px"
                      }}
                    >
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell">Référence</th>
                            <th className="ant-table-cell">Date de création</th>
                            <th className="ant-table-cell">Facture/BL</th>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell"></th>
                          </tr>
                        </thead>

                        <tbody className="ant-table-tbody">
                          {reglements &&
                            reglements.map((reglement, i) => (
                              <tr key={i}>
                                <td className="ant-table-cell">
                                  {reglement.s__ref}
                                </td>
                                <td className="ant-table-cell">
                                  {reglement.createdDate}
                                </td>

                                <td className="ant-table-cell">
                                  <b>{reglement.ref}</b>
                                </td>
                                <td className="ant-table-cell">
                                  <Descriptions
                                    column={6}
                                    bordered
                                    layout="vertical"
                                    size="small"
                                  >
                                    {reglement.type == 1 ||
                                    reglement.type == 2 ? (
                                      <>
                                        <Descriptions.Item className="item-label">
                                          <b>{getReglementType(reglement)}</b>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label="Nom"
                                          className="item-label"
                                        >
                                          {reglement.data.nom}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label="Date"
                                          className="item-label"
                                        >
                                          {toConvertDate(reglement.data.date)}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label="Banque"
                                          className="item-label"
                                        >
                                          {reglement.data.banque}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label="Num"
                                          className="item-label"
                                        >
                                          {reglement.data.num}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label="Montant"
                                          className="item-label"
                                        >
                                          {reglement.data.montant}
                                        </Descriptions.Item>
                                      </>
                                    ) : (
                                      <Descriptions.Item
                                        label="Montant"
                                        className="item-label"
                                      >
                                        {reglement.data.montant}
                                      </Descriptions.Item>
                                    )}
                                  </Descriptions>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {reglements.length < 1 && (
                        <Empty
                          style={{ textAlign: "center" }}
                          description="Aucun réglements disponible"
                        ></Empty>
                      )}
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>{" "}
            <div style={{ textAlign: "right", marginTop: 20 }}>
              <div
                style={{
                  display: "inline-block",
                  textAlign: "right"
                }}
              >
                <p>
                  <b>Tota HT</b> : {(bl.d__total - bl.d__tva).toFixed(3)} DT
                </p>
                <p>
                  <b>TVA</b> : {bl.d__tva.toFixed(3)} DT
                </p>
                <p>
                  <b>Remise Totale</b> : {bl.d__discount.toFixed(3)} DT
                </p>
                <p>
                  <b>Remise en %</b> :{" "}
                  {(
                    (bl.d__discount / (bl.d__discount + bl.d__total)) *
                    100
                  ).toFixed(2)}{" "}
                </p>
                <p>
                  <b>Net à Payer</b> : {bl.d__total.toFixed(3)} DT
                </p>
              </div>
            </div>
          </>
        )}
      </LoadingScreen>
    </Modal>
  );
};

export default BonLivraisonModal;
