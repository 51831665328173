import CAxios from "../../Utils/CAxios";
import printJS from "print-js";

export const toGetTotalHT = async (date) => {
  const { data } = await CAxios.post(`/api/user/bl`, { date: date });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetCAYear = async (year, id) => {
  const { data } = await CAxios.post(`/api/ca/year`, { year, id });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetCAYearAchat = async (year, id) => {
  const { data } = await CAxios.post(`/api/achat/year`, { year, id });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetCAMonth = async (year, month, users) => {
  const { data } = await CAxios.post(`/api/ca/month`, { year, month, users });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetCAMonthAchat = async (year, month) => {
  const { data } = await CAxios.post(`/api/achat/month`, { year, month });
  return data.success && data.success.stats ? data.success.stats : [];
};
export const toGetCAPerYear = async (years) => {
  const { data } = await CAxios.post(`/api/ca/years`, { years });
  return data.success && data.success.stats ? data.success.stats : [];
};
export const toGetCAAchatPerYear = async (years) => {
  const { data } = await CAxios.post(`/api/ca_achat/years`, { years });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetTotalTTC = async (date, entityId) => {
  const { data } = await CAxios.post(`/api/user/blttc`, {
    date: date,
    entityId: entityId,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetTotalEntities = async (selectedDate, entityIds, entity) => {
  const { data } = await CAxios.post(`/api/entities/stat`, { selectedDate, entityIds, entity });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetQuotesBLEntities = async (selectedDate, entityIds, entity) => {
  const { data } = await CAxios.post(`/api/entities/blquote/stat`, { selectedDate, entityIds, entity });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetTotalClients = async (selectedDate, entityIds, entity) => {
  const { data } = await CAxios.post(`/api/clients/stat`, { selectedDate, entityIds, entity });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetQuotesBLClients = async (selectedDate, entityIds, entity) => {
  const { data } = await CAxios.post(`/api/clients/blquote/stat`, { selectedDate, entityIds, entity });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetTotalUser = async (start, end, ids) => {
  const { data } = await CAxios.post(`/api/user/total`, {
    start: start,
    end: end,
    users: ids,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetCaisseData = async (start, end, id) => {
  const { data } = await CAxios.post(`/api/caisse/all`, {
    start,
    end,
    user: id,
  });
  return data.success ? data.success : null;
};

export const toPrintFiche = async (date, end, userId) => {
  try {
    const { data } = await CAxios.get(`/api/fiche/print/${date}/${end}/${userId}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintExercice = async (year, id) => {
  try {
    const { data } = await CAxios.get(`/api/exercice/print/${year}/${id}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const toAddDivers = async (datas) => {
  const { data } = await CAxios.post(`/api/divers/add`, datas);
  return data.success && data.success.divers ? data.success.divers : null;
};

export const toRemoveDivers = async (id) => {
  const { data } = await CAxios.post(`/api/divers/remove`, { id });
  return data.success && data.success.divers ? data.success.divers : null;
};

export const toGetDivers = async (start, end) => {
  const { data } = await CAxios.post(`/api/divers/list`, { start, end });
  return data.success && data.success.divers ? JSON.parse(data.success.divers) : [];
};
